:root {
  --primaryColor: #0370F3;
  --secondaryColor: #e32b1e;
  --backgroundColor: #fefefe;
  --surfaceColor: #f0ede6;
  --onPrimaryColor: #fefefe;
  --onSecondaryColor: #0d0c0c;
  --onBackgroundColor: #0d0c0c;
  --onSurfaceColor: #0d0c0c;

  --lightColor: #fefefe;
  --darkColor: #0d0c0c;

  --lowIndex: 1;
  --midIndex: 10;
  --highIndex: 100;
  --ultraHighIndex: 1000;
  --maxIndex: 10000;

  --sideBarWidth: 300px;
  --topBarHeight: 60px;

  /* shadows */
  --outerShadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
  --midOuterShadow: rgba(0, 0, 0, 0.3) 0px 19px 38px, rgba(0, 0, 0, 0.22) 0px 15px 12px;
  --borderOuterShadow:rgba(0, 0, 0, 0.12) 0px 1px 3px, rgba(0, 0, 0, 0.24) 0px 1px 2px;
  --innerShadow: rgba(50, 50, 93, 0.25) 0px 30px 60px -12px inset, rgba(0, 0, 0, 0.3) 0px 18px 36px -18px inset;
  --midInnerShadow: rgba(0, 0, 0, 0.17) 0px -23px 25px 0px inset, rgba(0, 0, 0, 0.15) 0px -36px 30px 0px inset, rgba(0, 0, 0, 0.1) 0px -79px 40px 0px inset, rgba(0, 0, 0, 0.06) 0px 2px 1px, rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 8px 4px, rgba(0, 0, 0, 0.09) 0px 16px 8px, rgba(0, 0, 0, 0.09) 0px 32px 16px;
}

/*---------------- Fonts ---------------*/
@font-face {
  font-family: "FontRegular";
  src: local("FontRegular"), url("./fonts/Poppins/Regular.ttf") format("truetype");
}

@font-face {
  font-family: "FontBold";
  src: local("FontBold"), url("./fonts/Poppins/Bold.ttf") format("truetype");
}

@font-face {
  font-family: "FontLight";
  src: local("FontLight"), url("./fonts/Poppins/Light.ttf") format("truetype");
}

@font-face {
  font-family: "FontSemiBold";
  src: local("FontSemiBold"), url("./fonts/Poppins/SemiBold.ttf") format("truetype");
}

.font-bold{
  font-family: "FontBold" !important;
}

.font-regular{
  font-family: "FontRegular" !important;
}

.font-light{
  font-family: "FontLight" !important;
}

.font-semi-bold{
  font-family: "FontSemiBold" !important;
}

/*********** FONTS END Here *******************/

html,
body {
  background: var(--backgroundColor);
  color: var(--onBackgroundColor);
  font-family: "FontRegular";
  width: 100%;
  height: 100%;
  font-size: 1em;
}

#root {
  width: 100%;
  height: 100%;
}

* {
  word-wrap: break-word;
}


/********* WIDGETS *********/

[class^="col-"] {
  margin-bottom: 20px;
}
.Paper {
  position: fixed;
  z-index: var(--highIndex);
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  width: 100vw;
  height: 100vh;
  background: none;
  transition: all .7s ease-out !important;
}

.mobileOnly {
  display: none;
}

@media (max-width: 991.98px) {

  .mobileOnly {
    display: block;
  }
}

.mShadow1 {
  box-shadow: var(--outerShadow);
}

.mShadow2 {
  box-shadow: var(--midOuterShadow);
}

.mShadow3 {
  box-shadow: var(--borderOuterShadow);
}

.mShadow4 {
  box-shadow: var(--innerShadow);
}

.mShadow5 {
  box-shadow: var(--midInnerShadow);
}

.mCard {
  width: 100%;
  height: 170px;
  overflow: hidden;
  padding: 10px;
  border-radius: 10px;
  box-shadow: var(--outerShadow);
}

.mCard 
.d-flex {
  height: 100%;
}

.mCard 
.d-flex >
.mOne,
.mCard 
.d-flex >
.mTwo {
  width: 50%;
  align-self: center;
}

.mFormDayParamHolder {
  width: 100%;
  padding: 10px;
}

.mFormDayParamHolder:nth-child(odd) {
  background: none;
}

.mFormDayParamHolder:nth-child(even) {
  background: var(--surfaceColor);
}


.mFormDayParamHolder >
h6 {
  width: 50%;
  align-self: flex-end;
  margin: 0;
  padding: 5px;
  flex-shrink: 0;
  text-transform: uppercase;
  font-size: 14px;
}

.mFormDayParamHolder >
.mInput {
  width: 25%;
  align-self: center;
  padding: 5px;
  flex-shrink: 0;
}

.mFormDayParamHolder >
.mInput >
label {
  font-size: 12px;
  font-family: "FontBold";
}

.mSectionHeader {
  margin-top: calc(0.6 * var(--topBarHeight));
  margin-bottom: calc(0.2 * var(--topBarHeight));
  font-family: "FontLight";
  font-size: 32px;
}

.text-primary {
  color: var(--primaryColor) !important;
}

.text-secondary {
  color: var(--secondaryColor) !important;
}

.text-light {
  color: var(--lightColor) !important;
}

.text-dark {
  color: var(--darkColor) !important;
}

.bg-primary {
  color: var(--onPrimaryColor) !important;
  background: var(--primaryColor) !important;
}

.bg-secondary {
  color: var(--onSecondaryColor) !important;
  background: var(--secondaryColor) !important;
}

.bg-surface {
  color: var(--onSurfaceColor) !important;
  background: var(--surfaceColor) !important;
}

.bg-background {
  color: var(--onBackgroundColor) !important;
  background: var(--backgroundColor) !important;
}


.bg-light {
  color: var(--darkColor) !important;
  background: var(--lightColor) !important;
}

.bg-dark {
  color: var(--lightColor) !important;
  background: var(--darkColor) !important;
}

.mNoMargin {
  margin: 0px !important;
}

.mNoPadding {
  padding: 0px !important;
}

.mBorder10 {
  border-radius: 10px !important;
}

.mBorder15 {
  border-radius: 15px !important;
}

.mBorder20 {
  border-radius: 20px !important;
}


.mNoShadow {
  box-shadow: none !important;
}

.mNoBg {
  background: none !important;
}

.mSupportLoading,
.mSupportLoader {
  min-width: 50px;
  min-height: 50px;
  position: relative;
}

.mOneLineText {
  width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.btn-rounded {
  border-radius: 30px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
}

button {
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.3); /* Adjust shadow values as needed */
  transition: box-shadow 0.3s ease; /* Add a smooth transition effect */
}

.dropdown-menu 
button {
  box-shadow: none;
}

.btn-padded {
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.btn-block {
  display: block;
  width: 100%;
}

pre {
  word-wrap: break-word;
  white-space: pre-wrap;
  font-family: "FontRegular";
}

small {
  font-size: 12px !important;
}

input,
select {
  height: 50px;
}

input,
select,
textarea {
  background: inherit !important;
  border: 1px solid var(--darkColor) !important;
}

input:focus,
select:focus,
textarea:focus {
  background: inherit !important;
  outline: none !important;
  box-shadow: none !important;
  border: 2px solid var(--darkColor) !important;
}

.form-group {
  width: 100%;
  padding-top: 15px;
  padding-bottom: 15px;
  word-wrap: break-word;
  overflow-x: hidden;
}

.form-group-table {
  width: 100%;
  padding-top: 15px;
  padding-bottom: 15px;
  overflow-x: auto;
}



label {
  font-family: 'FontLight';
  font-size: 18px;
  margin-bottom: 10px;
}

a {
  cursor: pointer;
}


/**********************/

/* MainLoader */
.MainLoader {
  position: fixed;
  z-index: var(--maxIndex);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.6);
}

/* Loader */
.Loader {
  width: 40px;
  height: 40px;
  position: absolute;
  border-radius: 20px;
  background: none;
  border: 4px solid var(--secondaryColor) !important;
  border-left: 4px solid var(--backgroundColor) !important;
  top: 50%;
  z-index: inherit;
  left: 50%;
  margin-left: -20px;
  margin-top: -20px;
  animation-name: "loading";
  animation-duration: 1s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;

  -webkit-animation-name: "loading";
  -webkit-animation-duration: 1s;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
}

@keyframes loading {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

/***** SideBar ******/
.SideBar {
  width: var(--sideBarWidth);
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  background: var(--surfaceColor);
  overflow-y: auto;
  z-index: var(--ultraHighIndex);
  transition: all .4s ease !important;
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  
}

.SideBar::-webkit-scrollbar {
  display: none;
}


.SideBar >
.mContainer {
  width: 85%;
  margin: 0 auto;
  background: inherit;
  height: 100%;
  padding-top: 20px;
  padding-bottom: var(--topBarHeight);
}



.SideBar >
.mContainer >
.mItem {
  width: 100%;
  height: calc(var(--topBarHeight) * 0.8);
  margin-bottom: 10px;
  background: none;
  color: var(--onSurfaceColor);
  padding: 10px;
  overflow: hidden;
  cursor: pointer;
}

.SideBar >
.mContainer >
.mItem[data-selected='true'],
.SideBar >
.mContainer >
.mItem:hover{
  background: var(--primaryColor);
  color: var(--onPrimaryColor);
}



.SideBar >
.mContainer >
.mItem >
.mIcon {
  font-size: 30px;
  align-self: center;
  margin: 0px;
  padding: 0px;
  flex-shrink: 0;
}


.SideBar >
.mContainer >
.mItem >
.mTitle {
  font-size: 16px;
  align-self: center;
  margin: 0px;
  padding: 0px;
  padding-left: 10px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  flex-grow: 1;
  font-family: 'FontRegular';
}

.SideBar
.mobileOnly {
  display: none;
}

@media (max-width: 991.98px) {
  
  .SideBar[data-closed='true'] {
    left: calc(-1 * var(--sideBarWidth));
  }
  
  .SideBar[data-closed='false'] {
    left: 0 !important;
  }

  .SideBar
  .mobileOnly {
    display: block;
  }
}

/******* TopBar ************/
.TopBar {
  position: fixed;
  height: var(--topBarHeight);
  width: 100%;
  background: var(--backgroundColor);
  box-shadow: var(--borderOuterShadow);
  z-index: var(--highIndex);
  top: 0;
  left: 0;
  right: 0;
}

.TopBar >
.container {
  height: 100%;
}

@media (min-width: 992px) {
  
  .TopBar{
    width: calc(100% - var(--sideBarWidth));
    right: 0;
    left: var(--sideBarWidth);
  }

  .TopBar[data-is-viewer='true'] {
    width: 100%;
    right: 0;
    left: 0;
  }
}

/******* MainBody **********/
.MainBody {
  width: 100%;
  padding: 0;
  margin: 0;
  padding-top: var(--topBarHeight);
  padding-bottom: var(--topBarHeight);
}

@media (min-width: 992px) {
  
  .MainBody {
    width: calc(100% - var(--sideBarWidth));
    float: right;
  }
}


.OnePatient {
  width: 100%;
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 10px;
  box-shadow: var(--borderOuterShadow);
}